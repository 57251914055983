body {
  margin: 0;
  font-family: GmarketSans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'GmarketSans';
  src: url('./asset/fonts/GmarketSansMedium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GmarketSans';
  src: url('./asset/fonts/GmarketSansLight.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GmarketSans';
  src: url('./asset/fonts/GmarketSansBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}